import { ref, watchEffect } from 'vue'
import { db } from '@/firebaseConfig'

export default function getDocument(collection, id) {
  const document = ref({})
  const error = ref(null)

  const docRef = db.collection(collection).doc(id)

  const unsub = docRef.onSnapshot(doc => {
    // need to make sure the doc exists & has data
    if (doc.data()) {
      document.value = { ...doc.data(), id: doc.id }
      error.value = null
    }
    else {
      error.value = 'The document does not exist'
    }
  }, (err) => {
    console.error(err.message)
    document.value = null
    error.value = 'Could not fetch data'
  })

  watchEffect((onInvalidate) => {
    // unsub when component is unmounted
    onInvalidate(() => unsub())
  })

  return { error, document }
}
